import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
var txt = "";


if(localStorage.islogged == 1){
  txt = "account";
}else{
  txt = "login";
}


const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: txt,
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'selectdata',
    loadChildren: () => import('./selectdata/selectdata.module').then( m => m.SelectdataPageModule)
  },
  {
    path: 'userinfo',
    loadChildren: () => import('./userinfo/userinfo.module').then( m => m.UserinfoPageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'yourorder',
    loadChildren: () => import('./yourorder/yourorder.module').then( m => m.YourorderPageModule)
  },
  {
    path: 'orderdetails',
    loadChildren: () => import('./orderdetails/orderdetails.module').then( m => m.OrderdetailsPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'changename',
    loadChildren: () => import('./changename/changename.module').then( m => m.ChangenamePageModule)
  },
  {
    path: 'editpaymend',
    loadChildren: () => import('./editpaymend/editpaymend.module').then( m => m.EditpaymendPageModule)
  },
  {
    path: 'editaddress',
    loadChildren: () => import('./editaddress/editaddress.module').then( m => m.EditaddressPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'editcart',
    loadChildren: () => import('./editcart/editcart.module').then( m => m.EditcartPageModule)
  },
  {
    path: 'selectsub',
    loadChildren: () => import('./selectsub/selectsub.module').then( m => m.SelectsubPageModule)
  },
  {
    path: 'shipping',
    loadChildren: () => import('./shipping/shipping.module').then( m => m.ShippingPageModule)
  },
  {
    path: 'independent',
    loadChildren: () => import('./independente/independente.module').then( m => m.IndependentePageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./checkout/checkout.module').then( m => m.CheckoutPageModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./orders/orders.module').then( m => m.OrdersPageModule)
  },
  {
    path: 'shippingcontent',
    loadChildren: () => import('./shippingcontent/shippingcontent.module').then( m => m.ShippingcontentPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
